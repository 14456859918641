import { createApi } from '@reduxjs/toolkit/query/react'

import { axiosBasePublicQuery } from '../../utils/http'
import { DEFAULT_FILTERS } from '../../constants/companies'
import { IFindAllCompaniesParams } from '../../services/companies'

export const companiesApi = createApi({
  reducerPath: 'companies',
  baseQuery: axiosBasePublicQuery({ baseUrl: '/companies/v1/companies' }),
  keepUnusedDataFor: 30,
  tagTypes: ['list', 'item'],
  endpoints: (build) => ({
    findAll: build.query<HTTPTypes.List<CompaniesTypes.Company>, IFindAllCompaniesParams>({
      query: (params = DEFAULT_FILTERS) => ({
        url: `/`,
        method: 'get',
        params,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['list'],
    }),
    findOne: build.query<CompaniesTypes.Company, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'get',
      }),
      providesTags: ['item'],
    }),
    create: build.mutation<CompaniesTypes.Company, Partial<CompaniesTypes.Company>>({
      query: (params) => ({
        url: `/`,
        method: 'post',
        data: params,
      }),
      invalidatesTags: ['list'],
    }),
    update: build.mutation<CompaniesTypes.Company, Partial<CompaniesTypes.Company>>({
      query: (params) => ({
        url: `/${params?._id}`,
        method: 'patch',
        data: params,
      }),
      invalidatesTags: ['list', 'item'],
    }),
    remove: build.mutation<CompaniesTypes.Company, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'delete',
      }),
      invalidatesTags: ['list'],
    }),
    import: build.query<CompaniesTypes.ImportedHouse, { companyId: string; complexId: string; params: Record<string, string> }>({
      query: ({ companyId, complexId, params }) => ({
        url: `/${companyId}/complexes/${complexId}/import`,
        method: 'post',
        data: params,
        timeout: 15000,
      }),
    }),
    sync: build.query<CompaniesTypes.SyncedHouse, { companyId: string; complexId: string; params: Record<string, string> }>({
      query: ({ companyId, complexId, params }) => ({
        url: `/${companyId}/complexes/${complexId}/sync`,
        method: 'post',
        data: params,
        timeout: 15000,
      }),
    }),
    updateFilters: build.mutation<Record<string, any>, Record<string, any>>({
      queryFn: (params = DEFAULT_FILTERS) => ({ data: params }),
      // invalidatesTags: ['list'],
    }),
  }),
})

export const {
  useFindAllQuery,
  useLazyFindAllQuery,
  useFindOneQuery,
  useLazyFindOneQuery,
  useCreateMutation,
  useUpdateMutation,
  useRemoveMutation,
  useUpdateFiltersMutation,
  useLazyImportQuery,
  useLazySyncQuery,
} = companiesApi
