import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import qs from 'query-string'

import { SPECIFICATION } from '../constants/common'
import { runtimeConfig } from '../config'
import { keycloak } from './keycloak'

const authMiddleware = (config: AxiosRequestConfig) => {
  if (keycloak.token) {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${keycloak.token}`
  }
  if (localStorage.getItem(SPECIFICATION)) {
    // @ts-ignore
    config.headers['x-specification-id'] = `${localStorage.getItem(SPECIFICATION)}`
  }

  return config
}

export const apiClient = axios.create({
  // withCredentials: true,
  baseURL: runtimeConfig.API_PATH,
  timeout: 7500,
  paramsSerializer: (params) => {
    return qs.stringify(params)
  },
})

apiClient.interceptors.request.use(authMiddleware)

export const publicClient = axios.create({
  // withCredentials: true,
  baseURL: runtimeConfig.PUBLIC_SERVICE,
  timeout: 7500,
  paramsSerializer: (params) => {
    return qs.stringify(params)
  },
})

publicClient.interceptors.request.use(authMiddleware)

// instance.interceptors.response.use(
//   (config) => {
//     return config
//   },
//   async (error) => {
//     const originalRequest = error.config
//
//     if (error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
//       originalRequest._isRetry = true
//
//       try {
//         await updateToken()
//
//         return instance.request(originalRequest)
//       } catch (err) {
//         console.log(err)
//       }
//     }
//
//     throw error
//   },
// )

interface IOptions {
  baseUrl: string
}

interface IBaseQuery {
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
}

const makeAxiosBaseQuery = (type: 'api' | 'public') => {
  let client = apiClient

  if (type === 'public') {
    client = publicClient
  }

  return (options: IOptions): BaseQueryFn<IBaseQuery> => {
    const { baseUrl } = options

    return async ({ url, method, ...params }) => {
      try {
        const { data } = await client({ url: baseUrl + url, method, ...params })

        return { data }
      } catch (axiosError) {
        const error = axiosError as AxiosError

        return {
          error: { status: error.response?.status, data: error.response?.data },
        }
      }
    }
  }
}

export const axiosBaseApiQuery = makeAxiosBaseQuery('api')
export const axiosBasePublicQuery = makeAxiosBaseQuery('public')

export default apiClient
