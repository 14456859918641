import * as React from 'react'
import { safeInvoke } from '@r1team/react-utils'

import { Add, Danger, TickCircle } from '../../../icons'
import { Space } from '../../Space'
import { Text } from '../../Typography'

import { NotificationProps } from '../types/Notifications.types'
import { Notification as NotificationStyled, Icon, Close } from '../styles/Notifications.sc'

/**
 * @description Notification component.
 *
 * @component
 * @example
 * ```jsx
 * <Notification />
 * ```
 */
const Notification: React.FC<NotificationProps & { onClose: (id: NotificationProps['id']) => void }> = React.memo((props) => {
  const { id, type, message, closable = true, onClose, ...otherProps } = props

  const handleClickClose = React.useCallback(() => safeInvoke(onClose, id), [id, onClose])

  const iconNode = React.useMemo(() => {
    switch (type) {
      case 'success':
        return <Icon type={type} icon={<TickCircle />} />
      case 'error':
        return <Icon type={type} icon={<Danger />} />
    }
  }, [type])

  const messageNode = React.useMemo(() => {
    if (message) {
      return <Text size={14}>{message}</Text>
    }

    return null
  }, [message])

  const closeNode = React.useMemo(() => {
    if (closable) {
      return <Close size={20} icon={<Add />} onClick={handleClickClose} />
    }

    return null
  }, [closable])

  return (
    <NotificationStyled {...otherProps}>
      {closeNode}
      {iconNode}
      <Space direction="vertical" size={2}>
        {messageNode}
      </Space>
    </NotificationStyled>
  )
})

Notification.displayName = 'Notification'

export default Notification
