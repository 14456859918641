import Keycloak, { KeycloakError } from 'keycloak-js'
import { Logger } from '@r1team/react-libs'

import { runtimeConfig } from '../config'

const logger = new Logger('AUTH')

export const initOptions = {
  checkLoginIframe: false,
  'public-client': true,
  'confidential-port': 0,
  'ssl-required': 'none',
}

// @ts-ignore
export const keycloak = new Keycloak({
  url: runtimeConfig.OAUTH_URL,
  realm: runtimeConfig.OAUTH_REALM as string,
  clientId: runtimeConfig.OAUTH_CLIENT_ID as string,
})

keycloak.onAuthSuccess = () => {
  if (keycloak.tokenParsed) {
    logger.debug('User data', keycloak.tokenParsed)
    logger.debug('User token', keycloak.token)
  }
}

keycloak.onAuthError = (data: KeycloakError) => {
  logger.error('Auth error', data)
}

keycloak.onAuthRefreshSuccess = () => {
  logger.debug('Token was successfully refreshed', keycloak.token)
}

keycloak.onAuthRefreshError = () => {
  logger.debug('Token was not refreshed')
  keycloak.login()
}

export const updateToken = async () => {
  try {
    keycloak.updateToken(90)
  } catch (err) {
    console.error('Failed to refresh the token, or the session has expired')
  }
}
