import React from 'react'

import { ButtonProps } from '../types/Button.types'
import { Container, Text } from '../styles/Button.sc'

/**
 * @description A Button triggers an action or an event
 *
 * @component
 * @example
 * ```jsx
 * <Button variant="primary" color="green" disabled block>
 *   Primary <Icon size="s" icon={<MenuIcon />} />
 * </Button>
 * ```
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { variant, size, block, loading, disabled, className, children, ...restProps } = props

  return (
    <Container
      data-qa="Button"
      ref={ref}
      className={className}
      size={size}
      variant={variant}
      block={block}
      loading={loading}
      disabled={loading || disabled}
      {...restProps}
    >
      <Text>{children}</Text>
    </Container>
  )
})

Button.displayName = 'Button'

Button.defaultProps = {
  variant: 'primary',
  size: 40,
}

export default Button
