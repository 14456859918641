import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { DeviceInfoProvider } from '@r1team/react-hooks'

import { GlobalStyle } from './styles/global'
import { theme } from './styles/theme'
import Router from './Router'

import { AuthProvider } from './providers/auth'
import { fetchProfile } from './state/reducers/profile'
import { useAppDispatch } from './hooks/useAppDispatch'

import { Header, Container, Layout } from './components/layout'
import { Logo } from './components/common/Logo'
import { AppGuard } from './components/app/AppGuard'
import { AppErrorBoundary } from './components/app/AppErrorBoundary'
import { Notifications } from './components/ui/Notifications'

const App = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  React.useEffect(() => {
    dispatch(fetchProfile())
  }, [dispatch])

  return (
    <AuthProvider>
      <DeviceInfoProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Layout>
            <AppGuard>
              <Header logo={<Logo />} />
              <Container>
                <AppErrorBoundary>
                  <Router />
                </AppErrorBoundary>
              </Container>
            </AppGuard>
          </Layout>
          <Notifications />
        </ThemeProvider>
      </DeviceInfoProvider>
    </AuthProvider>
  )
}

export default App
