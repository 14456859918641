import React from 'react'
import { Link } from 'react-router-dom'

import { Content } from '../../../layout'

import { ArrowRightTail } from '../../../icons'
import { Space } from '../../../ui/Space'
import { Button } from '../../../ui/Button'
import { Icon } from '../../../ui/Icon'

import { CodeBlock, Code, Pre, Container, StatusImage, Message, StatusMessage, Heading } from '../styles/AppErrorBoundary.sc'

interface IState {
  hasError: boolean
  error: Error | null
}

const ServerError = (props: Record<string, unknown>) => {
  return (
    <svg {...props} viewBox="0 0 1012 401" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5063 1C9.46065 1 0.506348 9.95431 0.506348 21V173C0.506348 184.046 9.46065 193 20.5063 193H80.5063C91.552 193 100.506 184.046 100.506 173V121C100.506 109.954 109.461 101 120.506 101H279.506C290.552 101 299.506 92.0457 299.506 81V21C299.506 9.95431 290.552 1 279.506 1H20.5063Z"
        fill="currentColor"
      />
      <path
        d="M0.506348 321C0.506348 309.954 9.46066 301 20.5063 301H179.506C190.552 301 199.506 292.046 199.506 281V222C199.506 210.954 208.461 202 219.506 202H279.506C290.552 202 299.506 210.954 299.506 222V282C299.506 293.046 290.552 302 279.506 302H219.506C208.461 302 199.506 310.954 199.506 322V381C199.506 392.046 190.552 401 179.506 401H20.5063C9.46065 401 0.506348 392.046 0.506348 381V321Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M365.506 0C353.908 0 344.506 9.40203 344.506 21V281C344.506 292.598 353.908 302 365.506 302H423.506C435.104 302 444.506 311.402 444.506 323V379C444.506 390.598 453.908 400 465.506 400H622.506C634.104 400 643.506 390.598 643.506 379V122C643.506 110.402 634.104 101 622.506 101H565.506C553.908 101 544.506 91.598 544.506 80V21C544.506 9.40202 535.104 0 523.506 0H365.506ZM465.506 102C454.461 102 445.506 110.954 445.506 122V281C445.506 292.046 454.461 301 465.506 301H523.506C534.552 301 543.506 292.046 543.506 281V122C543.506 110.954 534.552 102 523.506 102H465.506Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M709.506 0C697.908 0 688.506 9.40203 688.506 21V281C688.506 292.598 697.908 302 709.506 302H767.506C779.104 302 788.506 311.402 788.506 323V379C788.506 390.598 797.908 400 809.506 400H966.506C978.104 400 987.506 390.598 987.506 379V122C987.506 110.402 978.104 101 966.506 101H909.506C897.908 101 888.506 91.598 888.506 80V21C888.506 9.40202 879.104 0 867.506 0H709.506ZM809.506 102C798.461 102 789.506 110.954 789.506 122V281C789.506 292.046 798.461 301 809.506 301H867.506C878.552 301 887.506 292.046 887.506 281V122C887.506 110.954 878.552 102 867.506 102H809.506Z"
        fill="currentColor"
      />
    </svg>
  )
}

class AppErrorBoundary extends React.Component<any, IState> {
  state = {
    hasError: false,
    error: null,
  }

  constructor(props: any) {
    super(props)
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    }
  }

  renderError() {
    const { error = { stack: null } } = this.state

    if (process.env.NODE_ENV === 'development') {
      return (
        <CodeBlock>
          <Code>
            <Pre>{error?.stack}</Pre>
          </Code>
        </CodeBlock>
      )
    }

    return (
      <React.Fragment>
        <StatusImage as={ServerError} />
        <Message direction="vertical" align="center" justify="center">
          <StatusMessage as={Space} direction="vertical" align="center" justify="center">
            <Heading>Не удалось загрузить страницу</Heading>
            <div>
              К сожалению, произошла ошибка&nbsp;на&nbsp;сервере.
              <br />
              Попробуйте зайти позднее или перейдите на&nbsp;главную.
            </div>
          </StatusMessage>
          <Link to={'/'}>
            <Button variant="action" size={48}>
              <Icon icon={<ArrowRightTail />} />
              На главную
            </Button>
          </Link>
        </Message>
      </React.Fragment>
    )
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return (
        <Content>
          <Container>{this.renderError()}</Container>
        </Content>
      )
    }

    return this.props.children
  }
}

export default AppErrorBoundary
