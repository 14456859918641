import * as React from 'react'

import { Role } from '../../../constants/roles'
import { hasRole } from '../../../utils/account'
import { useActiveSpecification } from '../../../state/reducers/profile'

import { ContentProps } from '../types/Container.types'
import { Wrapper, Inner } from '../styles/Container.sc'

const Container = React.forwardRef<HTMLDivElement, ContentProps>((props, ref) => {
  const { children, ...restProps } = props
  const activeSpecification = useActiveSpecification()

  const isMounter = React.useMemo(() => {
    if (activeSpecification) {
      return hasRole(activeSpecification, Role.Mounter)
    }

    return false
  }, [activeSpecification])

  return (
    <Wrapper ref={ref} data-qa="Container" isMounter={isMounter} {...restProps}>
      <Inner isMounter={isMounter}>{children}</Inner>
    </Wrapper>
  )
})

Container.displayName = 'Container'
Container.defaultProps = {}

export default Container
