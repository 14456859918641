import { EventEmitter } from 'events'
import { getUuid } from '@r1team/react-utils'

import { NotificationProps, Type, Callback } from '../types/Notifications.types'
import React from 'react'

const STATUSES: Record<string, Type> = {
  SUCCESS: 'success',
  ERROR: 'error',
}

const TIMEOUT = 5000

class NotificationManager extends EventEmitter {
  public notifications: NotificationProps[] = []

  constructor() {
    super()
  }

  create({ type = 'success', message, timeout = TIMEOUT }: NotificationProps) {
    const opts = {
      id: getUuid(),
      type,
      message,
    }

    this.notifications.unshift(opts)
    this.emitChange()

    if (timeout > 0) {
      setTimeout(() => {
        this.remove(opts.id)
      }, timeout)
    }
  }

  success(message: string, timeout: number = TIMEOUT) {
    this.create({
      type: STATUSES.SUCCESS,
      message,
      timeout,
    })
  }

  error(message: string | React.ReactNode, timeout: number = TIMEOUT) {
    this.create({
      type: STATUSES.ERROR,
      message,
      timeout,
    })
  }

  remove(id: NotificationProps['id']) {
    this.notifications = this.notifications.filter((n) => id !== n.id)
    this.emitChange()
  }

  emitChange() {
    this.emit('change', [...this.notifications])
  }

  addChangeListener(callback: Callback) {
    this.addListener('change', callback)
  }

  removeChangeListener(callback: Callback) {
    this.removeListener('change', callback)
  }
}

export default new NotificationManager()
