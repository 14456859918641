import * as React from 'react'

/**
 * @description Slash icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Slash />} />
 * ```
 */
export const Slash = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Slash" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m18.9 5-14 14m7.1 3c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

Slash.displayName = 'Slash'
