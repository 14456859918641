import React from 'react'

import { hasRole } from '../../../../utils/account'
import { Role } from '../../../../constants/roles'

import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useAuth } from '../../../../hooks/useAuth'
import { selectProfile, selectProfileIsLoading, useActiveSpecification } from '../../../../state/reducers/profile'

import { AppLoader } from '../../AppLoader'
import type { AuthGuardProps } from '../types/AppGuard.types'
import { AppForbidden } from '../../AppForbidden'

const KEYS_PROVIDER = '62ebc7b224ca5a45b7e46703'
const KEYS_ROLES = new Set([Role.Owner, Role.User])

// @ts-ignore
const AppGuard: React.FC<AuthGuardProps> = (props) => {
  const { children } = props
  const { keycloak } = useAuth()
  const activeSpecification = useActiveSpecification()
  const profile = useAppSelector(selectProfile)
  const profileIsLoading = useAppSelector(selectProfileIsLoading)

  if (keycloak.authenticated && (profile === null || !activeSpecification) && !profileIsLoading) {
    return <AppForbidden />
  }

  if (!keycloak.authenticated || !profile) {
    return <AppLoader />
  }

  // if (KEYS_PROVIDER !== profile?.providerId || !KEYS_ROLES.has(profile?.role)) {
  //   return <AppForbidden />
  // }

  if (hasRole(activeSpecification, Role.Admin)) {
    return <AppForbidden />
  }

  return children
}

AppGuard.displayName = 'AppGuard'

export default AppGuard
