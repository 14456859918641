import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { Logger } from '@r1team/react-libs'

import * as service from '../../services/accounts'
import { AVAILABLE_ROLES, SPECIFICATION } from '../../constants/common'
import { useAppSelector } from '../../hooks/useAppSelector'
import { RootState } from '../store'

const logger = new Logger('PROFILE')

export interface State {
  data: AccountsTypes.Account | null
  specification: string | null
  specificationsMap: Record<string, AccountsTypes.Specification> | null
  loading: boolean
  error: any
}

const initialState: State = {
  data: null,
  specification: null,
  specificationsMap: null,
  loading: false,
  error: null,
}

export const fetchProfile = createAsyncThunk('profile/fetch', async (_, thunkAPI) => {
  try {
    return service.findMyAccount()
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить пользователя')
  }
})

export const updateProfile = createAsyncThunk('profile/update', async (data: Partial<AccountsTypes.Account>, thunkAPI) => {
  try {
    if (data._id) {
      return service.updateAccount(data._id, data)
    }

    return thunkAPI.rejectWithValue('Не удалось обновить пользователя')
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось обновить пользователя')
  }
})

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProfile.fulfilled.type]: (state, action: PayloadAction<AccountsTypes.Account>) => {
      state.loading = false
      state.error = null
      state.data = action.payload

      logger.debug('data', action.payload)

      const map = action.payload.specifications.reduce((output: Record<string, AccountsTypes.Specification>, specification) => {
        if (AVAILABLE_ROLES.includes(specification.role)) {
          output[specification._id] = specification
        }
        return output
      }, {})
      const activeSpecification = action.payload.specifications.find((specification) => specification)

      if (activeSpecification) {
        localStorage.setItem(SPECIFICATION, activeSpecification._id)
        logger.debug('specification', activeSpecification._id)

        state.specificationsMap = map
        state.specification = activeSpecification._id
      }
    },
    [fetchProfile.pending.type]: (state) => {
      state.loading = true
    },
    [fetchProfile.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.error = action.payload
    },

    [updateProfile.fulfilled.type]: (state, action: PayloadAction<AccountsTypes.Account>) => {
      state.loading = false
      state.error = null
      state.data = action.payload

      logger.debug('data', action.payload)
    },
  },
})

export const selectProfile = (state: RootState): State['data'] => state.profile.data
export const selectProfileIsLoading = (state: RootState): State['loading'] => state.profile.loading
export const selectProfileSpecification = (state: RootState): State['specification'] => state.profile.specification
export const selectProfileSpecificationsMap = (state: RootState): State['specificationsMap'] => state.profile.specificationsMap

export const useProfile = (): Partial<AccountsTypes.Account> => {
  const profile = useAppSelector(selectProfile) || {}

  return profile
}
export const useProfileSpecifications = () => useAppSelector(selectProfileSpecificationsMap)
export const useActiveSpecification = () => {
  const active = useAppSelector(selectProfileSpecification)
  const map = useAppSelector(selectProfileSpecificationsMap)

  if (active && map) {
    return map[active]
  }

  return null
}

export const useHasRole = (role: string): boolean => {
  const activeSpecification = useActiveSpecification()

  if (activeSpecification) {
    return activeSpecification.role === role
  } else {
    return false
  }
}

export const useHasPermission = (permission: string): boolean => {
  const { permissions: activePermissions = [] } = useActiveSpecification() || {}

  return activePermissions.includes(permission)
}

export default profileSlice.reducer
