import * as React from 'react'

import { Space } from '../../Space'
import { Icon } from '../../Icon'

import { MenuItemProps } from '../types/MenuItem.types'
import { Item, Text, Description, Control } from '../styles/Menu.sc'

const ChildrenWrapper = (args: Record<string, any>) => {
  const { children, ...props } = args
  return React.cloneElement(children, props)
}

/**
 * @description Menu Item component.
 *
 * @component
 * @example
 * ```jsx
 * <Menu.Item hint="hint">Content</Menu.Item>
 * ```
 */
const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>((props, ref) => {
  const { value, hint, description, active, disabled, variant, icon, control, children, ...restProps } = props

  const controlElement = React.useMemo(() => {
    return control
  }, [active, disabled, control])

  return (
    <Item ref={ref} data-qa="Item" active={active} disabled={disabled} variant={variant} {...restProps}>
      {icon && (
        <Control disabled={disabled}>
          <Icon size={20} icon={icon} />
        </Control>
      )}
      {control && <Control disabled={disabled}>{controlElement}</Control>}

      <Space direction="vertical" align="start" size={0}>
        {hint && (
          <Description disabled={disabled} variant={variant} size={12}>
            {hint}
          </Description>
        )}
        <Text>{children}</Text>
        {description && (
          <Description disabled={disabled} variant={variant} size={12}>
            {description}
          </Description>
        )}
      </Space>
    </Item>
  )
})

MenuItem.displayName = 'MenuItem'
MenuItem.defaultProps = {}

export default MenuItem
