import React from 'react'
import { useDispatch } from 'react-redux'
import { createApi } from '@reduxjs/toolkit/query/react'

import { axiosBasePublicQuery } from '../../utils/http'

export const keysApi = createApi({
  reducerPath: 'keys',
  baseQuery: axiosBasePublicQuery({ baseUrl: '/keys/v1/keys' }),
  keepUnusedDataFor: 30,
  tagTypes: ['list', 'item'],
  endpoints: (build) => ({
    findAll: build.query<HTTPTypes.List<KeysTypes.Key>, Record<string, string>>({
      query: (params) => ({
        url: `/`,
        method: 'get',
        params,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['list'],
    }),
    findOne: build.query<KeysTypes.Key, Partial<KeysTypes.Key>>({
      query: ({ _id }) => ({
        url: `/${_id}`,
        method: 'get',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['item'],
    }),
    create: build.mutation<KeysTypes.Key, Partial<KeysTypes.Key>>({
      query: (params) => ({
        url: `/`,
        method: 'post',
        data: params,
      }),
      invalidatesTags: ['list'],
    }),
    remove: build.mutation<KeysTypes.Key, Partial<KeysTypes.Key>>({
      query: ({ _id }) => ({
        url: `/${_id}`,
        method: 'delete',
      }),
      invalidatesTags: ['list'],
    }),
    findAllEvents: build.query<HTTPTypes.List<KeysTypes.Event>, Record<string, string>>({
      query: (params) => ({
        url: `/events`,
        method: 'get',
        params,
      }),
    }),
  }),
})

export const useListRefresh = () => {
  const dispatch = useDispatch()

  return React.useCallback(() => dispatch(keysApi.util.invalidateTags(['list'])), [dispatch])
}

export const { useFindAllQuery, useLazyFindAllQuery, useLazyFindAllEventsQuery, useCreateMutation, useRemoveMutation, useFindOneQuery } = keysApi
