export const PAGE_SIZE = 10

export const FILTER_FIELDS_NAMES = {
  PAGE: 'page',
  SEARCH: 'search',
  SORT: 'sort',
  SKIP: 'skip',
  LIMIT: 'limit',
}

export const DEFAULT_FILTERS: Record<string, any> = {
  [FILTER_FIELDS_NAMES.PAGE]: 1,
  [FILTER_FIELDS_NAMES.SEARCH]: '',
  [FILTER_FIELDS_NAMES.SORT]: '-created',
}

export const FILTER_SCHEME = {
  [FILTER_FIELDS_NAMES.PAGE]: Number,
  [FILTER_FIELDS_NAMES.SEARCH]: String,
  [FILTER_FIELDS_NAMES.SORT]: String,
}
