import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { useActiveSpecification } from '../../../state/reducers/profile'
import { NAV_FOOTER_LINKS, ROUTES_LINKS, ROUTES_NAMES } from '../../../constants/routes'
import { Role } from '../../../constants/roles'
import { isMounterProfile } from '../../../utils/account'

import { FooterProps } from '../types/Footer.types'
import { MenuItem, Wrapper } from '../styles/Footer.sc'
import { Space } from '../../ui/Space'
import { Button } from '../../ui/Button'
import { Icon } from '../../ui/Icon'
import { Book } from '../../icons'
import { Text } from '../../ui/Typography'
import { Tooltip } from '../../ui/Tooltip'

const menu = NAV_FOOTER_LINKS.map((key) => ({
  key,
  link: ROUTES_LINKS[key],
  name: ROUTES_NAMES[key],
}))

const Footer = React.forwardRef<HTMLDivElement, FooterProps>((props, ref) => {
  const { ...restProps } = props
  const navigate = useNavigate()
  const activeSpecification = useActiveSpecification()

  const link = React.useMemo(() => {
    if (activeSpecification?.role === Role.Mounter) {
      return 'https://r1-team.notion.site/22230e950431439b90194295d8443c54'
    }

    return 'https://r1-team.notion.site/e85dfdcdc10c436998631b8e25d86e48'
  }, [activeSpecification])

  const isMounter = React.useMemo(() => {
    return isMounterProfile(activeSpecification)
  }, [activeSpecification])

  const goToLink = React.useCallback((link: string) => {
    navigate(link)
  }, [])

  const navigation = React.useMemo(() => {
    if (!activeSpecification || isMounter) {
      return null
    }

    return (
      <Space size={isMounter ? 16 : 2} direction={isMounter ? 'horizontal' : 'vertical'}>
        {menu.map(({ link, name }) => {
          return (
            <MenuItem key={link} onClick={() => goToLink(link)}>
              {name}
            </MenuItem>
          )
        })}
      </Space>
    )
  }, [activeSpecification, isMounter])

  return (
    <Wrapper isMounter={isMounter} ref={ref} data-qa="Footer" {...restProps}>
      <a href={link} target="_blank" rel="noreferrer">
        <Tooltip title="Есть вопросы по кабинету? Ознакомьтесь со всеми возможностями.">
          <Button variant="text">
            <Icon size={20} icon={<Book />} />
            <Text size={14}>Справка о сервисе</Text>
          </Button>
        </Tooltip>
      </a>
      {navigation}
    </Wrapper>
  )
})

Footer.displayName = 'Footer'
Footer.defaultProps = {}

export default Footer
