import { Role } from '../constants/roles'

export const hasRole = (specification: AccountsTypes.Specification | null, role: string): boolean => {
  if (specification) {
    return specification.role === role
  }

  return false
}

export const hasPermission = (specification: AccountsTypes.Specification | null, permission: string): boolean => {
  if (specification) {
    return specification.permissions.includes(permission)
  }

  return false
}

export const isMounterProfile = (specification: AccountsTypes.Specification | null) => {
  if (specification) {
    return hasRole(specification, Role.Mounter)
  }

  return false
}
