import React from 'react'
import { Route, Routes } from 'react-router'

import { useAppSelector } from './hooks/useAppSelector'
import { selectProfile } from './state/reducers/profile'

import { AppLoader } from './components/app/AppLoader'

import ErrorPage from './pages/error'

const KeysPage = React.lazy(() => import(/* webpackChunkName: "keys.page" */ './pages/keys'))

const Router = () => {
  const profile = useAppSelector(selectProfile)

  if (!profile) {
    return null
  }

  return (
    <React.Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path="/" element={<KeysPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </React.Suspense>
  )
}

export default Router
